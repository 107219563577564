import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";

const StdDateField = ({
  formVals,
  name,
  handleFieldChange,
  handleFieldTouch,
  ...rest
}) => {
  const fieldModel = formVals.get(name);
  const label = fieldModel.get("label");
  const value = fieldModel.get("val_ipt");
  const helperText = fieldModel.get("err_msg");
  const isError = helperText !== " ";
  const disabled = fieldModel.get("disabled");

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        onChange={(newVal) => handleFieldChange(name, newVal)}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={() => handleFieldTouch(name)}
            variant="filled"
            fullWidth
            helperText={helperText}
            error={isError || params.error}
          />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default StdDateField;
