import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import InternalLink from "../../UIBits/InternalLink";
import { formatDateObjAsDateStr, formatName, ucWord } from "../../../helpers";
import HumReadyChip from "../../UIBits/HumReadyChip";
import OccupancyStatusChip from "../../UIBits/OccupancyStatusChip";
import SubscriptionStatusChip from "../../UIBits/SubscriptionStatusChip";

const BuildingCard = ({ buildingName, units }) => {
  const cardTitle = `${buildingName}: Unit Status`;
  return (
    <Card variant="outlined" sx={{ borderRadius: 2 }}>
      <CardHeader title={cardTitle} />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Unit</TableCell>
              <TableCell>Resident</TableCell>
              <TableCell>Plan</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Occupancy Status</TableCell>
              <TableCell>Hum Ready</TableCell>
              <TableCell>Subscription Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {units.map((u) => {
              const name = formatName(u.res_name_first, u.res_name_last, "N/A");
              const nameLink =
                name === "N/A" ? (
                  name
                ) : (
                  <InternalLink label={name} to={`/units/${u.id}`} />
                );
              const plan = u.plan ? ucWord(u.plan) : "N/A";
              const startDate = u.occ_id
                ? formatDateObjAsDateStr(new Date(u.occ_started_on))
                : "N/A";
              const endDate = u.occ_id
                ? formatDateObjAsDateStr(new Date(u.occ_planned_end))
                : "N/A";

              return (
                <TableRow key={u.id}>
                  <TableCell>
                    <InternalLink label={u.num} to={`/units/${u.id}`} />
                  </TableCell>
                  <TableCell>{nameLink}</TableCell>
                  <TableCell>{plan}</TableCell>
                  <TableCell>{startDate}</TableCell>
                  <TableCell>{endDate}</TableCell>
                  <TableCell>
                    <OccupancyStatusChip
                      is_occupied={u.occ_id}
                      planned_end={u.occ_planned_end}
                    />
                  </TableCell>
                  <TableCell>
                    <HumReadyChip connected={u.connected} />
                  </TableCell>
                  <TableCell>
                    <SubscriptionStatusChip reg_status={u.occ_reg_status} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default BuildingCard;
