import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useOutletContext } from "react-router";

const handleAddACH = async (propertyId, api) => {
  const response = await api.newPropertyPayment(propertyId);
  window.location.href = response.session_url;
};

const PagePaymentMethods = ({ pageData }) => {
  const { api } = useOutletContext();

  return (
    <>
      <Card elevation={0} variant="outlined" sx={{ borderRadius: 2 }}>
        <CardHeader
          titleTypographyProps={{ fontSize: "larger" }}
          title="Payment Methods Table"
        />
        <CardContent>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ "& > th": { whiteSpace: "noWrap" } }}>
                <TableCell>Property</TableCell>
                <TableCell>ACH Account Nickname</TableCell>
                <TableCell>ACH Account Number</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData.map((p) => (
                <TableRow key={`propertyId-${p.id}`}>
                  <TableCell>{p.name}</TableCell>
                  <TableCell>{p.stripe_payment_name || "N/A"}</TableCell>
                  <TableCell>
                    {p.stripe_payment_last_4
                      ? `...${p.stripe_payment_last_4}`
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {p.stripe_payment_id ? (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleAddACH(p.id, api);
                        }}
                      >
                        Update ACH
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleAddACH(p.id, api);
                        }}
                      >
                        Add ACH +
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default PagePaymentMethods;
