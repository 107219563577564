import { Grid } from "@mui/material";
import PropertyCard from "../PropertyCard";

const PageProperties = ({ pageData }) => {
  return (
    <Grid container direction="row" spacing={2}>
      {pageData.map((p) => (
        <Grid item key={p.id}>
          <PropertyCard property={p} navigationTarget={`/properties/${p.id}`} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PageProperties;
