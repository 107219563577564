import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";
import { Box, Button, Toolbar, Typography, useTheme } from "@mui/material";
import TopBar from "../Components/Layout/TopBar";
import { useOutletContext } from "react-router";

const DRAWER_WIDTH = 256;

const procPageTitleFn = (pageTitleFn, pageData) => {
  if (!pageTitleFn || !pageData) {
    return {};
  }

  const res = pageTitleFn(pageData);
  if (res.title) {
    return { titleOverride: res.title, backBtn: res.backBtn };
  }

  return { titleOverride: res };
};

const pageLoadMachine = createMachine({
  id: "page-load",
  initial: "loading",
  context: {
    pageData: null,
    errorMsg: "",
    loadStart: 0,
    loadEnd: 0,
  },
  states: {
    loading: {
      entry: ["load", "saveLoadStart"],
      on: {
        ACCEPT: { target: "loaded" },
        REJECT: { target: "error" },
      },
      exit: ["saveLoadEnd"],
    },
    error: {
      entry: ["saveErrMsg"],
      on: {
        RETRY: { target: "loading" },
      },
      exit: ["clearErrMsg"],
    },
    loaded: {
      initial: "idle",
      states: {
        idle: {
          entry: ["savePageData"],
          on: {
            REFRESH: { target: "refreshing" },
          },
        },
        refreshing: {
          entry: ["load", "saveLoadStart"],
          on: {
            ACCEPT: { target: "idle" },
            REJECT: { target: "error" },
          },
          exit: ["saveLoadEnd"],
        },
        error: {
          entry: ["saveErrMsg"],
          on: {
            REFRESH: { target: "refreshing" },
          },
          exit: ["clearErrMsg"],
        },
      },
    },
  },
});

const PageWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        ml: { md: `${DRAWER_WIDTH}px` },
        bgcolor: "#ebeef0",
        minHeight: "100vh",
      }}
    >
      <Toolbar />
      {children}
    </Box>
  );
};

const withInitialApiCall =
  (WrappedComponent, apiCall, pageTitle, pageTitleFn) => (props) => {
    const theme = useTheme();
    const {
      api,
      userPerms,
      acctOverride,
      overrideAcct,
      handleLogout,
      handleDrawerToggle,
    } = useOutletContext();

    const [state, send] = useMachine(pageLoadMachine, {
      actions: {
        load: () => {
          apiCall()
            .then((resp) => {
              if (resp?.statusCode === 500) {
                send({ type: "REJECT", msg: resp.message });
                return;
              }
              if (resp?.statusCode === 404) {
                console.log("here!");
                send({ type: "REJECT", msg: "404: Page not found" });
                return;
              }

              send({ type: "ACCEPT", data: resp });
            })
            .catch((err) => send({ type: "REJECT", msg: err.message }));
        },
        saveLoadStart: assign({ loadStart: () => +new Date() }),
        saveLoadEnd: assign({ loadEnd: () => +new Date() }),
        saveErrMsg: assign({ errorMsg: (_, e) => e.msg }),
        clearErrMsg: assign({ errorMsg: "" }),
        savePageData: assign({ pageData: (_, e) => e.data }),
      },
    });

    const loadTime = state.context.loadEnd - state.context.loadStart;

    const { titleOverride, backBtn } = procPageTitleFn(
      pageTitleFn,
      state.context.pageData
    );

    const TB = (
      <TopBar
        api={api}
        setAcctOverride={overrideAcct}
        userPerms={userPerms}
        drawerWidth={DRAWER_WIDTH}
        pageTitle={titleOverride || pageTitle || "[SET PAGE TITLE]"}
        showBackBtn={!!backBtn}
        handleDrawerToggle={handleDrawerToggle}
        handleLogout={handleLogout}
        acctOverride={acctOverride}
      />
    );

    if (state.matches("loading")) {
      return (
        <>
          {TB}
          <PageWrapper>
            <Typography>Loading...</Typography>
          </PageWrapper>
        </>
      );
    }

    if (state.matches("error")) {
      return (
        <>
          {TB}
          <PageWrapper>
            <Typography>error: {state.context.errorMsg}</Typography>
            <Button variant="outlined" onClick={() => send("RETRY")}>
              Retry
            </Button>
          </PageWrapper>
        </>
      );
    }

    return (
      <>
        {TB}
        <PageWrapper>
          <Box sx={{ p: 3 }}>
            <WrappedComponent
              refresh={() => send("REFRESH")}
              refreshErr={state.context.errorMsg}
              loadTime={loadTime}
              pageData={state.context.pageData}
              {...props}
            />
            <Typography
              mt={2}
              component="div"
              variant="caption"
              sx={{ color: theme.palette.grey["400"] }}
            >
              API Call: {loadTime}ms
            </Typography>
          </Box>
        </PageWrapper>
      </>
    );
  };

export default withInitialApiCall;
