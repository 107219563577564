import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SecondaryButton from "../../UIBits/SecondaryButton";

const ResendRegistrationDialog = ({ open, onClose, submitResendLink }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        This will send another text message to the resident with their Hum
        registration link.
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" color="error" onClick={onClose}>
          Cancel
        </SecondaryButton>
        <SecondaryButton variant="outlined" onClick={submitResendLink}>
          Send Link
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResendRegistrationDialog;
