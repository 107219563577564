import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatDateObjAsHumanReadable, formatPhone } from "../../helpers";

const PageUsers = ({ pageData }) => {
  return (
    <Card variant="outlined" sx={{ borderRadius: 2 }}>
      <CardHeader title="All Users" />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last Login</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData.map((u) => (
              <TableRow key={u.id}>
                <TableCell>{u.name}</TableCell>
                <TableCell>{u.email}</TableCell>
                <TableCell>{formatPhone(u.phone) || "Not Specified"}</TableCell>
                <TableCell>{u.active ? "Active" : "Inactive"}</TableCell>
                <TableCell>
                  {u.last_login_on
                    ? formatDateObjAsHumanReadable(new Date(u.last_login_on))
                    : "Never"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default PageUsers;
