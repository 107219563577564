import { format } from "date-fns";
import { CardContent, CardHeader, Stack, Typography } from "@mui/material";
import MoneyCardItem from "./MoneyCardItem";
import HumCard from "../../UIBits/HumCard";

const MoneyCard = ({ expenses, revenue, noi }) => {
  const curMonth = format(new Date(), "MMMM");
  return (
    <HumCard sx={{ minHeight: "463px" }}>
      <CardHeader
        title="Net Operating Income"
        subheader={`${curMonth} Projected`}
      />
      <CardContent>
        <Stack direction="column" spacing={5}>
          <MoneyCardItem variant="mr" amt={revenue} total={revenue} />
          <MoneyCardItem variant="e" amt={expenses} total={revenue} />
          <MoneyCardItem variant="noi" amt={noi} total={revenue} />
        </Stack>
      </CardContent>
      <CardContent sx={{ mt: 3 }}>
        <Typography variant="body2">
          Monthly Revenue, Expenses & NOI estimates based on daily active
          resident count across all Hum enabled properties.
        </Typography>
      </CardContent>
    </HumCard>
  );
};

export default MoneyCard;
