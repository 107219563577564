import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const NavLink = ({ icon: IconComponent, text, to, indent }) => (
  <ListItemButton
    to={to}
    component={RouterLink}
    disableRipple
    sx={{ pl: indent ? 4 : 2 }}
  >
    <ListItemIcon>
      <IconComponent />
    </ListItemIcon>
    <ListItemText primary={text} />
  </ListItemButton>
);

export default NavLink;
