import { useOutletContext } from "react-router";
import withInitialApiCall from "../../HOC/withInitialApiCall";
import PageShowcase from "../Page/PageShowcase";

const PageShowcaseLoader = () => {
  const { api } = useOutletContext();
  return withInitialApiCall(
    PageShowcase,
    () => api.getTest(),
    "Dialog Showcase"
  )();
};

export default PageShowcaseLoader;
