import { useOutletContext } from "react-router";
import withInitialApiCall from "../../HOC/withInitialApiCall";
import PageInvoices from "../Page/PageInvoices";

const PageInvoicesLoader = () => {
  const { api } = useOutletContext();
  return withInitialApiCall(
    PageInvoices,
    () => api.listInvoices(),
    "Billing"
  )();
};

export default PageInvoicesLoader;
