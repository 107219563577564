import StatusChip from "./StatusChip";

// Sorry for this. Sometimes we have an is_exempt flag, sometime we have a reg_status.
// Exempt is higher priority than reg_status, however generally you should not pass both in.
const ExemptStatusChip = ({ exempt, reg_status }) => {
  if (exempt !== undefined) {
    const { label, level } = exempt
      ? { label: "Exempt", level: "negative" }
      : { label: "Not Exempt", level: "positive" };

    return <StatusChip label={label} level={level} />;
  }

  const { label, level } =
    reg_status === "exempt"
      ? { label: "Exempt", level: "negative" }
      : { label: "Not Exempt", level: "positive" };
  return <StatusChip label={label} level={level} />;
};

export default ExemptStatusChip;
