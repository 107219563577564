import { useState } from "react";
import { useOutletContext } from "react-router";
import {
  Alert,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import HumCard from "../UIBits/HumCard";
import PrimaryButton from "../UIBits/PrimaryButton";
import SecondaryButton from "../UIBits/SecondaryButton";

const randNum = () => Math.ceil(Math.random() * 200);

const PageShowcase = ({ pageData, refresh }) => {
  const { api } = useOutletContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newVal, setNewVal] = useState(randNum());
  const [submitErr, setSubmitErr] = useState("");

  const openDialog = () => {
    setSubmitErr("");
    setNewVal(randNum());
    setDialogOpen(true);
  };
  const closeDialog = () => setDialogOpen(false);

  const submitToServer = () => {
    api
      .setTest(newVal)
      .then((resp) => {
        if (resp.status !== "ok") {
          setSubmitErr(resp.message);
          return;
        }

        closeDialog();
        refresh();
      })
      .catch((err) => setSubmitErr(err.message));
  };

  return (
    <>
      <HumCard>
        <CardHeader title="Some Current Value" subheader="A Dialog Demo" />
        <CardContent>
          <Typography sx={{ fontSize: "larger" }}>
            Current Value: {pageData.value}
          </Typography>
        </CardContent>
        <CardActions>
          <PrimaryButton onClick={openDialog} variant="contained">
            Roll Dice
          </PrimaryButton>
        </CardActions>
      </HumCard>
      <Dialog open={dialogOpen} fullWidth onClose={closeDialog}>
        <DialogTitle>Changing The Value!</DialogTitle>
        <DialogContent>
          {submitErr ? (
            <Alert severity="error"> {submitErr}</Alert>
          ) : (
            `Your new value will be ${newVal} (picked by a fair die roll).`
          )}
        </DialogContent>
        <DialogActions>
          <Stack direction="row-reverse" spacing={2}>
            <PrimaryButton variant="contained" onClick={submitToServer}>
              Lets Go!
            </PrimaryButton>
            <SecondaryButton variant="outlined" onClick={closeDialog}>
              Just Kidding...
            </SecondaryButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PageShowcase;
