import { Box, Button, Paper, Typography } from "@mui/material";
import logo from "../../assets/hum-logo.png";
import { useAuth0 } from "@auth0/auth0-react";

const PageLogin = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Paper
      sx={{
        maxWidth: 800,
        minWidth: 380,
        width: 380,
        margin: "50px auto 0 auto",
        padding: "30px",
      }}
      elevation={3}
    >
      <Typography variant="h4" mb={4} align="center">
        {/* The Marc Margin */}
        <Box sx={{ marginLeft: "12px" }}>
          <img src={logo} width={320} alt="Hum Logo" />
        </Box>
        <Box sx={{ mt: -4 }}> Partner Portal</Box>
      </Typography>
      <Button
        variant="contained"
        onClick={loginWithRedirect}
        disableRipple
        fullWidth
      >
        Log In
      </Button>
    </Paper>
  );
};

export default PageLogin;
