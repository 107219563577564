import { useOutletContext } from "react-router";
import withInitialApiCall from "../../HOC/withInitialApiCall";
import PagePaymentMethods from "../Page/PagePaymentMethods";

const PagePaymentMethodsLoader = () => {
  const { api } = useOutletContext();
  return withInitialApiCall(
    PagePaymentMethods,
    () => api.listProperties(),
    "Billing"
  )();
};

export default PagePaymentMethodsLoader;
