import HumCard from "../../UIBits/HumCard";
import {
  Box,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import DonutGraph from "../../UIBits/DonutGraph";
import CircleIcon from "@mui/icons-material/Circle";

const ChipColorableIcon = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "iconColor",
})(({ iconColor, theme }) => {
  const color = theme.palette[iconColor]
    ? theme.palette[iconColor].main
    : iconColor;
  return {
    "& .MuiChip-icon": { color },
  };
});

const AccountTakeRateCard = ({
  units_total,
  inactive,
  active_not_registered,
  active,
}) => {
  const inactProp = (inactive / units_total) * 100;
  const anrProp = (active_not_registered / units_total) * 100;
  const actProp = (active / units_total) * 100;
  const onlineProp = anrProp + actProp;

  return (
    <HumCard>
      <CardHeader title="Unit Take Rate" />
      <CardContent sx={{ width: "318px", mx: "auto" }}>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              width: "100%",
              position: "absolute",
              top: "105px",
            }}
          >
            <Typography
              sx={{ textAlign: "center" }}
              variant="h4"
            >{`${Math.round(onlineProp)}%`}</Typography>
            <Typography
              sx={{
                fontSize: "larger",
                color: "text.secondary",
                textAlign: "center",
              }}
            >
              Units Online
            </Typography>
          </Box>
        </Box>
        <DonutGraph inactive={inactProp} activeNR={anrProp} active={actProp} />
      </CardContent>
      <CardContent>
        <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
          <ChipColorableIcon
            icon={<CircleIcon />}
            iconColor="#00C853"
            label={`Active - ${Math.round(actProp)}%`}
            variant="outlined"
          />
          <ChipColorableIcon
            icon={<CircleIcon />}
            iconColor="#F78500"
            label={`Active Not Registered - ${Math.round(anrProp)}%`}
            variant="outlined"
          />
          <ChipColorableIcon
            icon={<CircleIcon />}
            iconColor="#673AB7"
            label={`Inactive - ${Math.round(inactProp)}%`}
            variant="outlined"
          />
        </Stack>
      </CardContent>
    </HumCard>
  );
};

export default AccountTakeRateCard;
