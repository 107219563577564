import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import { Map } from "immutable";
import StdTextField from "./EditOccupancyForm/StdTextField";
import StdDateField from "./EditOccupancyForm/StdDateField";
import SecondaryButton from "../../UIBits/SecondaryButton";

const validDateRequired = (x) => (!x ? "field is required" : " ");
const validRequired = (x) => (x.length === 0 ? "field is required" : " ");
const validPhone = (x) => {
  if (x.length !== 10) {
    return "invalid phone number";
  }

  return " ";
};

const BLANK_FORM = Map({
  name_first: Map({
    label: "First Name",
    val_ipt: "",
    val_norm: "",
    first_visit: true,
    err_msg: " ",
    norm: (x) => x,
    valid: [validRequired],
    disabled: false,
  }),
  name_last: Map({
    label: "Last Name",
    val_ipt: "",
    val_norm: "",
    first_visit: true,
    err_msg: " ",
    norm: (x) => x,
    valid: [validRequired],
    disabled: false,
  }),
  email: Map({
    label: "Email Address",
    val_ipt: "",
    val_norm: "",
    first_visit: true,
    err_msg: " ",
    norm: (x) => x,
    valid: [validRequired],
    disabled: false,
  }),
  phone: Map({
    label: "Phone Number",
    val_ipt: "",
    val_norm: "",
    first_visit: true,
    err_msg: " ",
    norm: (x) => x.replaceAll(/\D/g, ""),
    valid: [validRequired, validPhone],
    disabled: false,
  }),
  occ_start: Map({
    label: "Occupancy Start Date",
    val_ipt: null,
    val_norm: null,
    first_visit: true,
    err_msg: " ",
    norm: (x) => x,
    valid: [validDateRequired],
    disabled: false,
  }),
  occ_end: Map({
    label: "Occupancy End Date",
    val_ipt: null,
    val_norm: null,
    first_visit: true,
    err_msg: " ",
    norm: (x) => x,
    valid: [validDateRequired],
    disabled: false,
  }),
  is_exempt: Map({
    label: "Hum Exempt",
    val_ipt: false,
    val_norm: false,
    first_visit: true,
    err_msg: " ",
    norm: (x) => x,
    valid: [],
    disabled: false,
  }),
});

const importInitVals = (initVals, mode, humReady) => {
  if (!initVals) {
    return humReady
      ? BLANK_FORM
      : BLANK_FORM.setIn(["is_exempt", "disabled"], true).setIn(
          ["is_exempt", "val_ipt"],
          true
        );
  }

  return BLANK_FORM.withMutations((o) => {
    o.setIn(["name_first", "val_ipt"], initVals.name_first || "");
    o.setIn(["name_last", "val_ipt"], initVals.name_last || "");
    o.setIn(["email", "val_ipt"], initVals.email || "");
    o.setIn(["phone", "val_ipt"], initVals.phone || "");
    o.setIn(
      ["occ_start", "val_ipt"],
      initVals.occ_start ? new Date(initVals.occ_start) : null
    );
    o.setIn(
      ["occ_end", "val_ipt"],
      initVals.occ_end ? new Date(initVals.occ_end) : null
    );
    o.setIn(["is_exempt", "val_ipt"], !!initVals.is_exempt);

    if (mode === "current") {
      o.setIn(["email", "disabled"], true);
      o.setIn(["occ_start", "disabled"], true);
      o.setIn(["is_exempt", "disabled"], true);
    }

    if (mode === "future" && !humReady) {
      o.setIn(["is_exempt", "disabled"], true);
    }
  });
};

const transformFromOnValChange = (formVals) => {
  return formVals
    .map((fieldDat) => {
      const norm = fieldDat.get("norm");
      return fieldDat.set("val_norm", norm(fieldDat.get("val_ipt")));
    })
    .map((fieldDat) => {
      const validate = fieldDat.get("valid");
      let valid = true;
      let msg = " ";
      validate.forEach((fn) => {
        if (!valid) return;
        if (fieldDat.get("first_visit") === true) return;
        const result = fn(fieldDat.get("val_norm"));
        if (result === " ") {
          return;
        }
        valid = false;
        msg = result;
      });
      return fieldDat.set("err_msg", msg);
    });
};

const modeFromProps = (initVals, isCurrent) => {
  if (isCurrent) return "current";
  if (!initVals) return "new";
  return "future";
};

const markAllTouched = (formVals) =>
  formVals.map((o) => o.set("first_visit", false));

const titleFromMode = (mode) => {
  if (mode === "current") return `Edit Current Resident`;
  if (mode === "future") return `Edit Future Resident`;
  return `Add Future Resident`;
};

const EditOccupancyDialog = ({
  open,
  onClose,
  initVals,
  isCurrent,
  humReady,
  onSubmit,
  onCancelClick,
}) => {
  const mode = modeFromProps(initVals, isCurrent);

  const [formVals, setFormVals] = useState(
    importInitVals(initVals, mode, humReady)
  );

  const handleOnClose = () => {
    onClose();
  };

  const handleFieldChange = (fldName, newVal) => {
    setFormVals((prevState) =>
      transformFromOnValChange(prevState.setIn([fldName, "val_ipt"], newVal))
    );
  };

  const handleFieldTouch = (fldName) => {
    setFormVals((prevState) =>
      transformFromOnValChange(prevState.setIn([fldName, "first_visit"], false))
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const resolved = transformFromOnValChange(markAllTouched(formVals));
    const submittable = resolved.reduce(
      (acc, o) => acc && o.get("err_msg") === " ",
      true
    );
    if (!submittable) {
      setFormVals(resolved);
      return;
    }
    const payload = resolved.map((o) => {
      let norm = o.get("val_norm");
      if (norm instanceof Date) {
        norm = norm.toISOString().substring(0, 10);
      }
      return norm;
    });
    onSubmit(payload.toJS(), mode);
    handleOnClose();
  };

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <DialogTitle>{titleFromMode(mode)}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container columns={2} columnSpacing={2}>
            <Grid item xs={1}>
              <StdTextField
                name="name_first"
                formVals={formVals}
                handleFieldTouch={handleFieldTouch}
                handleFieldChange={handleFieldChange}
              />
            </Grid>
            <Grid item xs={1}>
              <StdTextField
                name="name_last"
                formVals={formVals}
                handleFieldTouch={handleFieldTouch}
                handleFieldChange={handleFieldChange}
              />
            </Grid>
            <Grid item xs={1}>
              <StdTextField
                name="email"
                formVals={formVals}
                handleFieldTouch={handleFieldTouch}
                handleFieldChange={handleFieldChange}
              />
            </Grid>
            <Grid item xs={1}>
              <StdTextField
                name="phone"
                formVals={formVals}
                handleFieldTouch={handleFieldTouch}
                handleFieldChange={handleFieldChange}
              />
            </Grid>
            <Grid item xs={1}>
              <StdDateField
                name="occ_start"
                formVals={formVals}
                handleFieldTouch={handleFieldTouch}
                handleFieldChange={handleFieldChange}
                disablePast={mode === "new" || mode === "future"}
              />
            </Grid>
            <Grid item xs={1}>
              <StdDateField
                name="occ_end"
                formVals={formVals}
                handleFieldTouch={handleFieldTouch}
                handleFieldChange={handleFieldChange}
                disablePast={mode === "current"}
                minDate={formVals.getIn(["occ_start", "val_ipt"])}
              />
            </Grid>
            <Grid item xs={1}>
              <FormControlLabel
                disabled={formVals.getIn(["is_exempt", "disabled"])}
                control={
                  <Switch
                    color="error"
                    checked={formVals.getIn(["is_exempt", "val_ipt"])}
                    onChange={(e) =>
                      handleFieldChange("is_exempt", e.target.checked)
                    }
                  />
                }
                label={formVals.getIn(["is_exempt", "label"])}
              />
            </Grid>
            {isCurrent && (
              <Grid item xs={1} sx={{ textAlign: "right" }}>
                <SecondaryButton
                  onClick={() => {
                    onClose();
                    onCancelClick();
                  }}
                  color="error"
                  variant="outlined"
                >
                  Terminate Occupancy
                </SecondaryButton>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button variant="contained" type="submit">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditOccupancyDialog;
