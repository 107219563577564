import { Paper, Typography } from "@mui/material";

const SSNoDefinedStuff = ({ message, minHeight = "30vh" }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        minHeight,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        mt: 2,
      }}
    >
      <Typography variant="h5" sx={{ color: "text.secondary", mt: -3 }}>
        {message}
      </Typography>
    </Paper>
  );
};

export default SSNoDefinedStuff;
