import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { formatDateObjAsHumanReadable } from "../../../helpers";
import OccupancyStatusChip from "../../UIBits/OccupancyStatusChip";
import HumReadyChip from "../../UIBits/HumReadyChip";

const UnitDetailsCard = ({
  isOccupied,
  plannedEndDate,
  isConnected,
  connectedOn,
  osgGatewaySn,
  onuMac,
  onuSerial,
}) => {
  return (
    <Card sx={{ p: 0, borderRadius: 2 }} variant="outlined">
      <CardHeader title="Unit Details" />
      <CardContent>
        <List disablePadding>
          <ListItem>
            <ListItemText primary="Occupancy Status" />
            <ListItemSecondaryAction>
              <OccupancyStatusChip
                planned_end={plannedEndDate}
                is_occupied={isOccupied}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Hum Ready" />
            <ListItemSecondaryAction>
              <HumReadyChip connected={isConnected} />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Hum Ready Since" />
            <ListItemSecondaryAction>
              {connectedOn
                ? formatDateObjAsHumanReadable(new Date(connectedOn))
                : ""}
            </ListItemSecondaryAction>
          </ListItem>
          {isConnected && (
            <>
              <ListItem>
                <ListItemText primary="ONU MAC" />
                <ListItemSecondaryAction>{onuMac}</ListItemSecondaryAction>
              </ListItem>

              <ListItem>
                <ListItemText primary="ONU Serial Number" />
                <ListItemSecondaryAction>{onuSerial}</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="OpenSync Serial Number" />
                <ListItemSecondaryAction>
                  {osgGatewaySn}
                </ListItemSecondaryAction>
              </ListItem>
            </>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default UnitDetailsCard;
