import { Divider, List, ListSubheader } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BallotIcon from "@mui/icons-material/Ballot";
import GroupIcon from "@mui/icons-material/Group";
import HouseIcon from "@mui/icons-material/House";
import ViewListIcon from "@mui/icons-material/ViewList";
import NavLink from "./NavLink";
import logo from "../../assets/hum-logo.png";
import { Box } from "@mui/material";

const NavList = ({ drawerWidth }) => {
  return (
    <div>
      <Box sx={{ my: 4 }}>
        <img alt="Hum Logo" src={logo} width={drawerWidth - 1} />
      </Box>
      <Divider />
      <List
        subheader={<ListSubheader component="div">Main Menu</ListSubheader>}
      >
        <NavLink to="/dashboard" text="Dashboard" icon={DashboardIcon} />
        <NavLink to="/properties" text="Properties" icon={HouseIcon} />
        <NavLink to="/plans" text="Plans" icon={ViewListIcon} />
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div">Administration</ListSubheader>
        }
      >
        <NavLink to="/invoices" text="Invoices" icon={BallotIcon} />
        <NavLink
          to="/payment-methods"
          text="Payment Methods"
          icon={BallotIcon}
        />
        <NavLink to="/users" text="Users" icon={GroupIcon} />
      </List>
    </div>
  );
};

export default NavList;
