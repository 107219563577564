import { Box, LinearProgress, Typography } from "@mui/material";
import { amber, deepPurple, green } from "@mui/material/colors";
import { formatPrice } from "../../../helpers";

const variantDetails = (variant) => {
  switch (variant) {
    case "e":
      return {
        title: "Expenses",
        bgColor: deepPurple["50"],
        fgColor: "#673AB7",
      };
    case "noi":
      return {
        title: "Net Operating Income (from Internet)",
        bgColor: green["50"],
        fgColor: "#00C853",
      };
    case "mr":
    default:
      return {
        title: "Monthly Revenue",
        bgColor: amber["50"],
        fgColor: "#F78500",
      };
  }
};

const MoneyCardItem = ({ variant, amt, total }) => {
  const { title, bgColor, fgColor } = variantDetails(variant);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {title}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Typography sx={{ fontSize: "larger", color: "text.secondary" }}>
            {formatPrice(amt)}
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={(amt / total) * 100}
            sx={{
              height: 4,
              borderRadius: 2,
              color: fgColor,
              backgroundColor: bgColor,
              "& > span": {
                backgroundColor: fgColor,
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MoneyCardItem;
