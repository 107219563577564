import { Route, Routes } from "react-router-dom";
import LoggedInGuard from "./LoggedInGuard";
import Layout from "./Layout/Layout";
import PageLogin from "./Page/PageLogin";
import PageLogout from "./Page/PageLogout";
import PageNotFound from "./Page/PageNotFound";
import PageDashboardLoader from "./Loaders/PageDashboardLoader";
import PagePropertiesLoader from "./Loaders/PagePropertiesLoader";
import PagePlansLoader from "./Loaders/PagePlansLoader";
import PageInvoicesLoader from "./Loaders/PageInvoicesLoader";
import PagePaymentMethodsLoader from "./Loaders/PagePaymentMethodsLoader";
import PagePropertyLoader from "./Loaders/PagePropertyLoader";
import PageUsersLoader from "./Loaders/PageUsersLoader";
import PagePaymentMethodsCallback from "./Page/PagePaymentMethodsCallback";
import PageUnitLoader from "./Loaders/PageUnitLoader";
import PageShowcaseLoader from "./Loaders/PageShowcaseLoader";

const App = () => {
  return (
    <Routes>
      <Route element={<LoggedInGuard />}>
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<PageDashboardLoader />} />
          <Route path="/properties" element={<PagePropertiesLoader />} />
          <Route
            path="/properties/:propertyId"
            element={<PagePropertyLoader />}
          />
          <Route path="/units/:unitId" element={<PageUnitLoader />} />
          <Route path="/plans" element={<PagePlansLoader />} />
          <Route path="/invoices" element={<PageInvoicesLoader />} />
          <Route
            path="/payment-methods"
            element={<PagePaymentMethodsLoader />}
          />
          <Route
            path="/payment-methods-callback"
            element={<PagePaymentMethodsCallback />}
          />
          <Route path="/users" element={<PageUsersLoader />} />
          <Route path="/showcase" element={<PageShowcaseLoader />} />
        </Route>
      </Route>
      <Route path="/" element={<PageLogin />} />
      <Route path="/logout" element={<PageLogout />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
