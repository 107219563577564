import { Box, useTheme } from "@mui/material";

const C = 100;
const SW = 4;
const R = C / (2 * Math.PI);
const VB_SIDE = R * 2 + SW;
const VIEW_BOX = `0 0 ${VB_SIDE} ${VB_SIDE}`;

const DonutGraph = ({ sx, inactive, activeNR, active }) => {
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <svg width="100%" height="100%" viewBox={VIEW_BOX} className="donut">
        <circle
          cx="50%"
          cy="50%"
          r={R}
          fill="transparent"
          stroke="#00C853"
          strokeWidth={SW}
          strokeDasharray={`${active} ${100 - active}`}
          strokeDashoffset={25}
        />
        <circle
          cx="50%"
          cy="50%"
          r={R}
          fill="transparent"
          stroke="#F78500"
          strokeWidth={SW}
          strokeDasharray={`${activeNR} ${100 - activeNR}`}
          strokeDashoffset={25 - active}
        />
        <circle
          cx="50%"
          cy="50%"
          r={R}
          fill="transparent"
          stroke="#673AB7"
          strokeWidth={SW}
          strokeDasharray={`${inactive} ${100 - inactive}`}
          strokeDashoffset={25 - active - activeNR}
        />
      </svg>
    </Box>
  );
};

export default DonutGraph;
