import { useOutletContext } from "react-router";
import withInitialApiCall from "../../HOC/withInitialApiCall";
import PageDashboard from "../Page/PageDashboard";

const PageDashboardLoader = () => {
  const { api } = useOutletContext();
  return withInitialApiCall(
    PageDashboard,
    async () => {
      const res = await Promise.all([api.listMessages(), api.getAggs()]);
      return { messages: res[0], aggs: res[1] };
    },
    "Dashboard"
  )();
};

export default PageDashboardLoader;
