import StatusChip from "./StatusChip";

const XLATE = {
  exempt: { label: "Inactive", level: "default" },
  scheduled: { label: "Active - Not Registered", level: "middling" },
  sent: { label: "Active - Not Registered", level: "middling" },
  fulfilled: { label: "Active - Registered", level: "positive" },
};

// 'exempt', 'scheduled', 'sent', 'fulfilled'
const SubscriptionStatusChip = ({ reg_status }) => {
  let label, level;
  const vals = XLATE[reg_status];
  if (!vals) {
    label = "Inactive";
    level = "default";
  } else {
    label = vals.label;
    level = vals.level;
  }
  return <StatusChip label={label} level={level} />;
};

export default SubscriptionStatusChip;
