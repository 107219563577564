import { Chip, styled } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import { grey } from "@mui/material/colors";

const LEVELS = {
  positive: { color: "success", icon: CircleIcon },
  middling: { color: "warning", icon: CircleIcon },
  negative: { color: "error", icon: DoNotDisturbOnIcon },
  default: { color: grey[600], icon: TripOriginIcon },
};

const ChipColorableIcon = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "iconColor",
})(({ iconColor, theme }) => {
  const color = theme.palette[iconColor]
    ? theme.palette[iconColor].main
    : iconColor;
  return {
    "& .MuiChip-icon": { color },
  };
});

const StatusChip = ({ level, label }) => {
  const color = LEVELS[level] ? LEVELS[level].color : LEVELS["default"].color;
  const Icon = LEVELS[level] ? LEVELS[level].icon : LEVELS["default"].icon;

  return (
    <ChipColorableIcon
      icon={<Icon fontSize="small" />}
      iconColor={color}
      label={label}
      variant="outlined"
    />
  );
};

export default StatusChip;
