import { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { Navigate, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

const API_CALL_LOADING = "Loading";
const API_CALL_ERROR = "Error";

const PagePaymentMethodsCallback = () => {
  const { api } = useOutletContext();
  const queryParams = new URLSearchParams(document.location.search);
  const sessionId = queryParams.get("session_id");

  if (!sessionId) {
    return <Navigate to="/payment-methods" replace={true} />;
  }

  const navigate = useNavigate(); //

  const [apiCall, setApiCall] = useState(API_CALL_LOADING);

  useEffect(async () => {
    try {
      await api.successPropertyPayment({
        checkout_session_id: sessionId,
      });

      navigate("/payment-methods", {
        replace: true,
      });
    } catch (e) {
      console.error(e);
      setApiCall(API_CALL_ERROR);
    }
  }, [sessionId]);

  if (apiCall === API_CALL_ERROR) {
    return <Typography>Error Saving Stripe Data...</Typography>;
  }

  return <Typography>Saving Stripe Data...</Typography>;
};

export default PagePaymentMethodsCallback;
