import { intervalToDuration } from "date-fns";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  formatDateObjAsHumanReadable,
  formatPhone,
  formatPrice,
  ucWord,
} from "../../../helpers";
import ExemptStatusChip from "../../UIBits/ExemptStatusChip";
import SubscriptionStatusChip from "../../UIBits/SubscriptionStatusChip";
import { grey } from "@mui/material/colors";
import PrimaryButton from "../../UIBits/PrimaryButton";
import SecondaryButton from "../../UIBits/SecondaryButton";

const calcOccDurationInMonthsFromInterval = (dateStart, dateEnd) => {
  const occInterval = { start: dateStart, end: dateEnd };
  const duration = intervalToDuration(occInterval);
  const durationMonths = duration.years * 12 + duration.months;
  return `${durationMonths} month${durationMonths === 1 ? "" : "s"}`;
};

const CurrentResidentCard = ({ unit, onEditClick, onResendClick }) => {
  const dateStart = new Date(unit.occ_started_on);
  const dateEnd = new Date(unit.occ_planned_end);
  const term = calcOccDurationInMonthsFromInterval(dateStart, dateEnd);
  const showResendRegBtn = !!(
    unit.occ_reg_status === "sent" || unit.occ_reg_status === "scheduled"
  );

  const plan = unit.plan ? ucWord(unit.plan) : "N/A";

  return (
    <Card sx={{ mt: 4, borderRadius: 2 }} variant="outlined">
      <CardHeader
        title={`${unit.res_name_first} ${unit.res_name_last}`}
        subheader={
          <Box component="span" sx={{ color: "success.main" }}>
            Current Resident
          </Box>
        }
      />

      <CardContent>
        <Typography variant="body2">Resident Details</Typography>
        <List>
          <ListItem>
            <ListItemText>Phone Number</ListItemText>
            <ListItemSecondaryAction>
              {formatPhone(unit.res_phone)}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>Email Address</ListItemText>
            <ListItemSecondaryAction>{unit.res_email}</ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>

      <CardContent
        sx={{
          borderTopWidth: "1px",
          borderTopStyle: "solid",
          borderTopColor: grey[200],
        }}
      >
        <Typography variant="body2">Network Details</Typography>
        <List>
          <ListItem>
            <ListItemText>Initialization Date</ListItemText>
            <ListItemSecondaryAction>
              {unit.reg_fulfilled_on
                ? formatDateObjAsHumanReadable(unit.reg_fulfilled_on)
                : "N/A"}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>Subscription Status</ListItemText>
            <ListItemSecondaryAction>
              <SubscriptionStatusChip reg_status={unit.occ_reg_status} />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>Subscription</ListItemText>
            <ListItemSecondaryAction>{plan}</ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>Retail Price</ListItemText>
            <ListItemSecondaryAction>
              {unit.connected ? formatPrice(unit.prop_cost_res) : "N/A"}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>

      <CardContent
        sx={{
          borderTopWidth: "1px",
          borderTopStyle: "solid",
          borderTopColor: grey[200],
        }}
      >
        <Typography variant="body2">Occupancy Details</Typography>
        <List>
          <ListItem>
            <ListItemText>Occupancy Start Date</ListItemText>
            <ListItemSecondaryAction>
              {formatDateObjAsHumanReadable(dateStart)}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>Occupancy End Date</ListItemText>
            <ListItemSecondaryAction>
              {formatDateObjAsHumanReadable(dateEnd)}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>Occupancy Term</ListItemText>
            <ListItemSecondaryAction>{term}</ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>Resident Exempt from Hum</ListItemText>
            <ListItemSecondaryAction>
              <ExemptStatusChip reg_status={unit.occ_reg_status} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>

      <CardActions sx={{ mr: 1, mb: 1 }}>
        <Stack
          direction="row-reverse"
          justifyContent="flex-start"
          spacing={1}
          sx={{ width: "100%" }}
        >
          <PrimaryButton variant="contained" onClick={onEditClick}>
            Edit Occupancy Details
          </PrimaryButton>
          {showResendRegBtn && (
            <SecondaryButton variant="outlined" onClick={onResendClick}>
              Resend Registration Link
            </SecondaryButton>
          )}
        </Stack>
      </CardActions>
    </Card>
  );
};

export default CurrentResidentCard;
