import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { formatDateObjAsHumanReadable, formatPhone } from "../../../helpers";
import { intervalToDuration } from "date-fns";
import { grey } from "@mui/material/colors";
import ExemptStatusChip from "../../UIBits/ExemptStatusChip";

const calcOccDurationInMonthsFromInterval = (dateStart, dateEnd) => {
  const occInterval = { start: dateStart, end: dateEnd };
  const duration = intervalToDuration(occInterval);
  const durationMonths = duration.years * 12 + duration.months;
  return `${durationMonths} month${durationMonths === 1 ? "" : "s"}`;
};

const FutureResidentCard = ({ occf, onEditClick, onDeleteClick }) => {
  const dateStart = new Date(occf.planned_start);
  const dateEnd = new Date(occf.planned_end);
  const term = calcOccDurationInMonthsFromInterval(dateStart, dateEnd);

  return (
    <Card sx={{ mt: 4, borderRadius: 2 }} elevation={0} variant="outlined">
      <CardHeader
        title={`${occf.res_name_first} ${occf.res_name_last}`}
        subheader={
          <Box component="span" sx={{ color: "warning.main" }}>
            Future Resident
          </Box>
        }
      />
      <CardContent>
        <Typography variant="body2">Resident Details</Typography>
        <List>
          <ListItem>
            <ListItemText>Phone Number</ListItemText>
            <ListItemSecondaryAction>
              {formatPhone(occf.res_phone)}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>Email Address</ListItemText>
            <ListItemSecondaryAction>{occf.res_email}</ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>

      <CardContent
        sx={{
          borderTopWidth: "1px",
          borderTopStyle: "solid",
          borderTopColor: grey[200],
        }}
      >
        <Typography variant="body2">Occupancy Details</Typography>
        <List>
          <ListItem>
            <ListItemText>Occupancy Start Date</ListItemText>
            <ListItemSecondaryAction>
              {formatDateObjAsHumanReadable(dateStart)}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>Occupancy End Date</ListItemText>
            <ListItemSecondaryAction>
              {formatDateObjAsHumanReadable(dateEnd)}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>Occupancy Term</ListItemText>
            <ListItemSecondaryAction>{term}</ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>Resident Exempt from Hum</ListItemText>
            <ListItemSecondaryAction>
              <ExemptStatusChip exempt={occf.is_exempt} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>
      <CardActions
        sx={{ flexDirection: "row-reverse", mr: 1, mb: 1, columnGap: 2 }}
      >
        <Button variant="contained" onClick={onEditClick}>
          Edit Future Occupancy
        </Button>
        <Button variant="outlined" color="error" onClick={onDeleteClick}>
          Cancel Upcoming Occupancy
        </Button>
      </CardActions>
    </Card>
  );
};

export default FutureResidentCard;
