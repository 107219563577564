import { Card, CardMedia, Stack } from "@mui/material";
import PageNotFound from "./PageNotFound";
import BuildingCard from "./PageProperty/BuildingCard";
import PropertyAggregates from "./PageProperty/PropertyAggregates";

const PageProperty = ({ pageData }) => {
  if (pageData === null) {
    return <PageNotFound />;
  }

  return (
    <Stack direction="column" spacing={3}>
      <Card sx={{ borderRadius: 2 }} elevation={0}>
        <CardMedia component="img" height={288} image={pageData.picture} />
      </Card>
      <PropertyAggregates aggs={pageData.aggs} />
      {Object.entries(pageData.buildings).map(([bid, b]) => (
        <BuildingCard key={bid} buildingName={b.name} units={b.units} />
      ))}
    </Stack>
  );
};

export default PageProperty;
