import { useState } from "react";
import { Button, Card, CardMedia, Fab, Grid, Snackbar } from "@mui/material";
import CurrentResidentCard from "./PageUnit/CurrentResidentCard";
import AddIcon from "@mui/icons-material/Add";
import UnitDetailsCard from "./PageUnit/UnitDetailsCard";
import EditOccupancyDialog from "./PageUnit/EditOccupancyDialog";
import FutureResidentCard from "./PageUnit/FutureResidentCard";
import { useOutletContext } from "react-router";
import CancelOccupancyDialog from "./PageUnit/CancelOccupancyDialog";
import ResendRegistrationDialog from "./PageUnit/ResendRegistrationDialog";

const PageUnit = ({ pageData, refresh }) => {
  const { api } = useOutletContext();

  const [snackBarOpen, setSnackBarOpen] = useState("");
  const handleOpenSnackBar = (message) => setSnackBarOpen(message);
  const handleSnackBarClose = () => setSnackBarOpen("");

  const [selectedOccfId, setSelectedOccfId] = useState(null);
  const [editOccupancyDialogOpen, setEditOccupancyDialogOpen] = useState({
    open: false,
    model: null,
    isCurrent: false,
  });
  const handleEditOccupancyDialogOpen = (occData, isCurrent) =>
    setEditOccupancyDialogOpen({ open: true, model: occData, isCurrent });
  const handleEditOccupancyDialogClose = () =>
    setEditOccupancyDialogOpen({ open: false, model: null, isCurrent: false });

  const [cancelOccDialogOpen, setCancelOccDialogOpen] = useState(false);
  const handleOpenCancelOccDialog = () => setCancelOccDialogOpen(true);
  const handleCloseCancelOccDialog = () => setCancelOccDialogOpen(false);

  const [resendDialogOpen, setResendDialogOpen] = useState(false);
  const handleOpenResendDialog = () => setResendDialogOpen(true);
  const handleCloseResendDialog = () => setResendDialogOpen(false);

  const handleEditCurrentOcc = () => {
    handleEditOccupancyDialogOpen(
      {
        name_first: pageData.res_name_first,
        name_last: pageData.res_name_last,
        phone: pageData.res_phone,
        email: pageData.res_email,
        occ_start: pageData.occ_started_on,
        occ_end: pageData.occ_planned_end,
        is_exempt: pageData.occ_reg_status === "exempt",
      },
      true
    );
  };

  const handleEditFutureOccupancy = (occf) => {
    setSelectedOccfId(occf.id);
    handleEditOccupancyDialogOpen(
      {
        name_first: occf.res_name_first,
        name_last: occf.res_name_last,
        phone: occf.res_phone,
        email: occf.res_email,
        occ_start: occf.planned_start,
        occ_end: occf.planned_end,
        is_exempt: occf.is_exempt,
      },
      false
    );
  };

  const handleNewResident = () => {
    handleEditOccupancyDialogOpen(null, false);
  };

  const handleSubmitEditResidentDialog = (payload, mode) => {
    let callFn, msg, args;
    switch (mode) {
      case "current":
        callFn = "updateActiveOcc";
        args = [pageData.id, payload];
        msg = "Current resident details updated";
        break;
      case "new":
        callFn = "newResOccf";
        args = [pageData.id, payload];
        msg = "New resident added";
        break;
      case "future":
        callFn = "updateOccf";
        args = [selectedOccfId, payload];
        msg = "Future resident updated";
        break;
      default:
        throw new Error(`Unknown mode ${mode}`);
    }
    // yeah sorry for this hack... will clean up later
    api[callFn](...args)
      .then((resp) => {
        if (resp.status !== "ok") {
          handleOpenSnackBar(resp.message);
          return;
        }

        handleOpenSnackBar(msg);
        refresh();
      })
      .catch((err) => {
        handleOpenSnackBar(err.message);
      });
    setSelectedOccfId(null);
  };

  const handleSubmitDeleteFutureResidentDialog = (occfId) => {
    api
      .deleteOccf(occfId)
      .then((resp) => {
        if (resp.status !== "ok") {
          handleOpenSnackBar(resp.message);
          return;
        }

        handleOpenSnackBar("Future occupancy cancelled.");
        refresh();
      })
      .catch((err) => {
        handleOpenSnackBar(err.message);
      });
  };

  const submitResendLink = () => {
    handleCloseResendDialog();
    api
      .resendRegLink(pageData.occ_id)
      .then((resp) => {
        if (resp.status === "ok") {
          handleOpenSnackBar("Registration link sent.");
          return;
        }
        handleOpenSnackBar(resp.message);
      })
      .catch((err) => {
        handleOpenSnackBar(err.message);
      });
  };

  return (
    <>
      <Grid columns={8} container columnSpacing={4}>
        <Grid item xs={5}>
          <Card elevation={0} sx={{ borderRadius: 2 }}>
            <CardMedia component="img" height={288} image={pageData.picture} />
          </Card>
          {pageData.occ_id && (
            <CurrentResidentCard
              unit={pageData}
              onEditClick={handleEditCurrentOcc}
              onCancelClick={handleOpenCancelOccDialog}
              onResendClick={handleOpenResendDialog}
            />
          )}
          {pageData.occfs.map((occf) => (
            <FutureResidentCard
              key={occf.id}
              occf={occf}
              onEditClick={() => handleEditFutureOccupancy(occf)}
              onDeleteClick={() =>
                handleSubmitDeleteFutureResidentDialog(occf.id)
              }
            />
          ))}
        </Grid>
        <Grid item xs={3}>
          <UnitDetailsCard
            isOccupied={pageData.occ_id}
            plannedEndDate={pageData.planned_finish}
            isConnected={pageData.connected}
            registrationStatus={pageData.occ_reg_status}
            connectedOn={pageData.connected_on}
            osgGatewaySn={pageData.osg_hwserial}
            onuMac={pageData.onu_macaddr}
            onuSerial={pageData.onu_hwserial}
          />
        </Grid>
      </Grid>
      <Fab
        color="primary"
        variant="extended"
        sx={{ position: "fixed", right: 24, bottom: 24 }}
        onClick={handleNewResident}
      >
        <AddIcon /> &nbsp; Add New Resident
      </Fab>
      {editOccupancyDialogOpen.open && (
        <EditOccupancyDialog
          open={editOccupancyDialogOpen.open}
          initVals={editOccupancyDialogOpen.model}
          isCurrent={editOccupancyDialogOpen.isCurrent}
          onClose={handleEditOccupancyDialogClose}
          humReady={pageData.connected}
          onSubmit={handleSubmitEditResidentDialog}
          onCancelClick={handleOpenCancelOccDialog}
        />
      )}
      <ResendRegistrationDialog
        open={resendDialogOpen}
        onClose={handleCloseResendDialog}
        submitResendLink={submitResendLink}
      />
      {pageData.occ_id && (
        <CancelOccupancyDialog
          open={cancelOccDialogOpen}
          onClose={handleCloseCancelOccDialog}
          occId={pageData.occ_id}
          api={api}
          refresh={refresh}
        />
      )}
      <Snackbar
        open={!!snackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        message={snackBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        action={<Button onClick={handleSnackBarClose}>Dismiss</Button>}
      />
    </>
  );
};

export default PageUnit;
