import { useOutletContext } from "react-router";
import withInitialApiCall from "../../HOC/withInitialApiCall";
import PageProperty from "../Page/PageProperty";
import { useParams } from "react-router-dom";

const PagePropertyLoader = () => {
  const { api } = useOutletContext();
  const { propertyId } = useParams();

  return withInitialApiCall(
    PageProperty,
    () => api.getProperty(propertyId),
    "Property",
    (resp) => ({ title: resp.name, backBtn: true })
  )();
};

export default PagePropertyLoader;
