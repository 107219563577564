import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AcctSwitcher from "./AcctSwitcher";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const TopBar = ({
  pageTitle,
  showBackBtn,
  drawerWidth,
  handleDrawerToggle,
  handleLogout,
  api,
  userPerms,
  setAcctOverride,
  acctOverride,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useAuth0();
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
        background: "linear-gradient(178deg, #895FF8 20%, #0C76F9)",
      }}
      elevation={0}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        {showBackBtn && (
          <IconButton
            sx={{ mr: 2, color: "inherit" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {pageTitle}
        </Typography>
        {userPerms.includes("sysadmin") && (
          <AcctSwitcher
            setAcctOverride={setAcctOverride}
            api={api}
            acctOverride={acctOverride}
          />
        )}
        <div>
          <IconButton size="large" onClick={handleMenu} color="inherit">
            <Avatar alt={user.name} src={user.picture} />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleLogout({ returnTo: window.location.origin })}
            >
              Log Out
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
