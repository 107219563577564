const makeUrl = (pathParts, params = {}) => {
  const urlPath = ["", ...pathParts].join("/");
  const url = new URL(urlPath, process.env.REACT_APP_API_ROOT);
  const parsedParams = new URLSearchParams(params || {}).toString();
  return `${url.toString()}${parsedParams ? "?" : ""}${parsedParams}`;
};

const makeOpts = (method, token, body, acctOverride) => {
  const opts = {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  };

  if (body) {
    opts.body = JSON.stringify(body);
    opts.headers["Content-Type"] = "application/json";
  }

  if (acctOverride) {
    opts.headers["X-Acct"] = acctOverride;
  }

  return opts;
};

const ssApi = (token, acctOverride) => {
  const simpleCall = async (method, pathParts, params, body) => {
    const url = makeUrl(pathParts, params);
    const opts = makeOpts(method, token, body, acctOverride);
    const response = await fetch(url, opts);
    return response.json();
  };

  const listAccounts = () => simpleCall("GET", ["accounts"]);
  const listProperties = () => simpleCall("GET", ["properties"]);
  const getProperty = (id) => simpleCall("GET", ["properties", id]);
  const getMe = () => simpleCall("GET", ["me"]);
  const listMessages = () => simpleCall("GET", ["messages"]);
  const getUnit = (id) => simpleCall("GET", ["units", id]);
  const getAggs = () => simpleCall("GET", ["aggs"]);
  const newResOccf = (unitId, model) =>
    simpleCall("POST", ["units", unitId, "occupancy"], null, model);
  const updateActiveOcc = (unitId, model) =>
    simpleCall("PUT", ["units", unitId, "occupancy"], null, model);
  const updateOccf = (occfId, model) =>
    simpleCall("PUT", ["occf", occfId], null, model);
  const deleteOccf = (occfId) => simpleCall("DELETE", ["occf", occfId]);
  const newPropertyPayment = (propertyId) =>
    simpleCall("POST", ["properties", propertyId, "payment-method"], null);
  const successPropertyPayment = (model) =>
    simpleCall("POST", ["properties", "payment-method-success"], null, model);
  const listServiceOpts = () => simpleCall("GET", ["service-options"]);
  const listUsers = () => simpleCall("GET", ["users"]);
  const listInvoices = () => simpleCall("GET", ["invoices"]);
  const getInvoicePDFLink = (invoiceId) =>
    simpleCall("GET", ["invoices", invoiceId, "pdf"]);
  const getTest = () => simpleCall("GET", ["test"]);
  const setTest = (value) => simpleCall("POST", ["test"], null, { value });
  const cancelOcc = (occId) => simpleCall("DELETE", ["occ", occId]);
  const resendRegLink = (occId) =>
    simpleCall("POST", ["occ", occId, "reg-resend"]);

  return {
    getTest,
    setTest,
    listAccounts,
    listProperties,
    getProperty,
    getMe,
    listMessages,
    getAggs,
    getUnit,
    newResOccf,
    updateActiveOcc,
    updateOccf,
    deleteOccf,
    newPropertyPayment,
    successPropertyPayment,
    listServiceOpts,
    listUsers,
    listInvoices,
    getInvoicePDFLink,
    cancelOcc,
    resendRegLink,
  };
};

export default ssApi;
