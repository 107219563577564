import { useState } from "react";
import { Outlet, useOutletContext } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import NavDrawer from "./NavDrawer";

const DRAWER_WIDTH = 256;

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const outletContext = useOutletContext();
  const { logout } = useAuth0();

  const handleLogout = () =>
    logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL });
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <>
      <NavDrawer
        drawerWidth={DRAWER_WIDTH}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Outlet
        context={{ ...outletContext, handleLogout, handleDrawerToggle }}
      />
    </>
  );
};

export default Layout;
