import { CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { formatPrice } from "../../../helpers";
import HumCard from "../../UIBits/HumCard";

const AggCard = ({ title, value }) => {
  return (
    <HumCard sx={{ width: "100% " }}>
      <CardContent sx={{ pb: 0 }}>
        <Typography sx={{ color: "text.secondary" }}>{title}</Typography>
      </CardContent>
      <CardHeader title={value} />
    </HumCard>
  );
};

const PropertyAggregates = ({ aggs }) => {
  const unitsOnline = `${aggs.units_total - aggs.inactive} of ${
    aggs.units_total
  } Units`;
  const mrr = aggs.revenue;
  const mrc = aggs.expenses;
  const noi = aggs.noi;
  return (
    <Stack direction="row" spacing={3}>
      <AggCard title="# of Units Online" value={unitsOnline} />
      <AggCard title="Monthly Recurring Revenue" value={formatPrice(mrr, 2)} />
      <AggCard title="Monthly Expenses" value={formatPrice(mrc, 2)} />
      <AggCard title="Net Operating Income (NOI)" value={formatPrice(noi, 2)} />
    </Stack>
  );
};

export default PropertyAggregates;
