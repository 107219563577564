import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatPrice, formatSpeedTier } from "../../helpers";

const ServiceOptionCell = ({ opts, level }) => {
  const opt = opts[level];
  if (!opt) {
    return <TableCell>N/A</TableCell>;
  }

  const costFmt = `${formatPrice(opt.cost)}/mo.`;
  const fullCostFmt = level === "starter" ? costFmt : `Starter + ${costFmt}`;
  const speedFmt = formatSpeedTier(parseInt(opt.speed, 10));
  return (
    <TableCell>
      <Typography sx={{ fontSize: "larger" }}>{speedFmt}</Typography>
      <Typography variant="body2">{fullCostFmt}</Typography>
    </TableCell>
  );
};

const PagePlans = ({ pageData }) => {
  return (
    <Card sx={{ borderRadius: 2 }} variant="outlined">
      <CardHeader title="Plan Pricing by Property" />
      <CardContent>
        <TableContainer>
          <Table
            sx={{
              "& td": { textAlign: "center" },
              "& th": { textAlign: "center", width: "25%" },
              "& td:first-child": { textAlign: "left" },
              "& th:first-child": { textAlign: "left" },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Properties</TableCell>
                <TableCell>Starter</TableCell>
                <TableCell>Faster</TableCell>
                <TableCell>Fastest</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData.map((p) => (
                <TableRow key={p.id}>
                  <TableCell>{p.name}</TableCell>
                  <ServiceOptionCell opts={p.serviceOptions} level="starter" />
                  <ServiceOptionCell opts={p.serviceOptions} level="faster" />
                  <ServiceOptionCell opts={p.serviceOptions} level="fastest" />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default PagePlans;
