import { useOutletContext } from "react-router";
import withInitialApiCall from "../../HOC/withInitialApiCall";
import PagePlans from "../Page/PagePlans";

const PagePlansLoader = () => {
  const { api } = useOutletContext();
  return withInitialApiCall(PagePlans, () => api.listServiceOpts(), "Plans")();
};

export default PagePlansLoader;
