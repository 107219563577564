import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { Outlet } from "react-router";
import { useCallback, useEffect, useReducer } from "react";
import ssApi from "../api";
import { useNavigate } from "react-router-dom";

const initialAppLoad = {
  token: null,
  api: null,
  userPerms: null,
  acctList: [],
  acctOverride: null,
  loadError: "",
};

const reducer = (state, action) => {
  if (action.type === "token") {
    const api = ssApi(action.token, state.acctOverride);
    return {
      ...state,
      token: action.token,
      api,
      userPerms: null,
    };
  }

  if (action.type === "user") {
    return { ...state, userPerms: action.permissions };
  }

  if (action.type === "accts") {
    return { ...state, acctList: action.accts };
  }

  if (action.type === "override") {
    const api = ssApi(state.token, action.override);
    return { ...state, api, userPerms: null, acctOverride: action.override };
  }

  if (action.type === "error") {
    return { ...state, loadError: action.message };
  }
};

const checkIsAppLoaded = (appLoadContext) => {
  return (
    appLoadContext.api &&
    appLoadContext.userPerms &&
    appLoadContext.acctList.length > 0
  );
};

const LoggedInGuard = () => {
  const { error, isLoading, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  const navigate = useNavigate();
  const [appLoad, dispatch] = useReducer(reducer, initialAppLoad);
  const isAppLoaded = checkIsAppLoaded(appLoad);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (appLoad.token) return;
    getAccessTokenSilently()
      .then((token) => dispatch({ type: "token", token }))
      .catch((err) => dispatch({ type: "error", message: err.message }));
  }, [isAuthenticated, appLoad.token]);

  useEffect(() => {
    if (!appLoad.api) return;
    if (appLoad.userPerms) return;
    appLoad.api
      .getMe()
      .then((user) => {
        if (!user.permissions) {
          const userSer = JSON.stringify(user);
          const message = `Could not find permissions for ${userSer}`;
          dispatch({ type: "error", message });
          return;
        }
        dispatch({ type: "user", permissions: user.permissions });
      })
      .catch((err) => dispatch({ type: "error", message: err.message }));
  }, [appLoad.api, appLoad.userPerms]);

  useEffect(() => {
    if (!appLoad.api) return;
    if (appLoad.acctList.length > 0) return;
    appLoad.api
      .listAccounts()
      .then((acctList) => dispatch({ type: "accts", accts: acctList }))
      .catch((err) => dispatch({ type: "error", message: err.message }));
  }, [appLoad.api, appLoad.acctList]);

  const overrideAcct = useCallback(
    (newAcct) => {
      dispatch({ type: "override", override: newAcct });
    },
    [appLoad.acctOverride]
  );

  if (error) {
    return <Typography>Error: {error.message}</Typography>;
  }

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!isAuthenticated) {
    navigate("/");
  }

  if (appLoad.loadError) {
    return <Typography>Error: {appLoad.loadError}</Typography>;
  }

  if (!isAppLoaded) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Outlet
      context={{
        api: appLoad.api,
        userPerms: appLoad.userPerms,
        acctOverride: appLoad.acctOverride,
        acctList: appLoad.acctList,
        overrideAcct,
      }}
    />
  );
};

export default LoggedInGuard;
