import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";

const CancelOccupancyDialog = ({ open, onClose, occId, api, refresh }) => {
  const [uiState, setUiState] = useState({ err: null, calling: false });
  const handleCallError = (msg) => setUiState({ err: msg, calling: false });
  const handleCallStart = () => setUiState({ err: null, calling: true });

  const handleSubmit = () => {
    handleCallStart("");
    api
      .cancelOcc(occId)
      .then((resp) => {
        if (resp.status === "ok") {
          refresh();
          onClose();
          return;
        }

        handleCallError(resp.message);
      })
      .catch((err) => handleCallError(err.message));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Confirm Terminating Current Occupancy</DialogTitle>
      {uiState.err && (
        <DialogContent>
          <Alert severity="error">{uiState.err}</Alert>
        </DialogContent>
      )}
      {uiState.calling ? (
        <Box sx={{ mx: "auto", my: 1 }}>
          <CircularProgress />
        </Box>
      ) : (
        <DialogContent>
          Terminating the current occupancy will result in the current occupant{" "}
          <strong>immediately losing internet service</strong> and cannot be
          undone. Are you sure you want to terminate occupancy?
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Go Back
        </Button>
        <Button onClick={handleSubmit} variant="outlined" color="error">
          Terminate Current Occupancy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelOccupancyDialog;
