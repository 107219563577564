import { useOutletContext } from "react-router";
import withInitialApiCall from "../../HOC/withInitialApiCall";
import PageProperties from "../Page/PageProperties";

const PagePropertiesLoader = () => {
  const { api } = useOutletContext();
  return withInitialApiCall(
    PageProperties,
    () => api.listProperties(),
    "Properties"
  )();
};

export default PagePropertiesLoader;
