import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const InternalLink = ({ to, label, ...rest }) => {
  return (
    <Link component={RouterLink} underline="none" to={to} {...rest}>
      {label}
    </Link>
  );
};

export default InternalLink;
