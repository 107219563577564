import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

const switcherStyles = {
  "& .MuiAutocomplete-endAdornment .MuiIconButton-root": { color: "white" },
  "& label.MuiFormLabel-root": { color: "white", borderBottomColor: "white" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "white" },
    "&:hover fieldset": { borderColor: "white" },
    "&.Mui-focused fieldset": { borderColor: "white" },
    "& input": { color: "white" },
  },
};

const defaultPick = (list) => list.find((p) => p.name === "Hum Internet");
const getById = (list, code) => list.find((p) => p.code === code);

const AcctSwitcher = ({ api, setAcctOverride, acctOverride }) => {
  const [acctList, setAcctList] = useState(null);
  const [pickedValue, setPickedValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!api) {
      return;
    }
    api.listAccounts().then((resp) => {
      setAcctList(resp);
      if (acctOverride) {
        const picked = getById(resp, acctOverride);
        setPickedValue(picked);
        setInputValue(picked.name);
      } else {
        const def = defaultPick(resp);
        setPickedValue(def);
        setInputValue(def.name);
      }
    });
  }, []);

  if (!acctList) {
    return null;
  }

  return (
    <Autocomplete
      renderInput={(params) => <TextField sx={switcherStyles} {...params} />}
      value={pickedValue}
      inputValue={inputValue}
      onChange={(_, val) => {
        const pick = val || defaultPick(acctList);
        setPickedValue(pick);
        setAcctOverride(pick.code);
      }}
      onInputChange={(_, val) => setInputValue(val)}
      size="small"
      margin="dense"
      options={acctList}
      includeInputInList
      getOptionLabel={(o) => o.name}
      sx={{ width: 250 }}
    />
  );
};

export default AcctSwitcher;
