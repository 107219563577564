import StatusChip from "./StatusChip";

const distillLabelLevel = (is_occupied, planned_end) => {
  if (!is_occupied) {
    return { label: "Vacant", level: "default" };
  }

  const now = new Date();
  const planned_end_date = new Date(planned_end);
  if (now > planned_end_date) {
    return { label: "Month to Month", level: "middling" };
  }

  return { label: "Occupied", level: "positive" };
};

const OccupancyStatusChip = ({ is_occupied, planned_end }) => {
  const { label, level } = distillLabelLevel(is_occupied, planned_end);
  return <StatusChip label={label} level={level} />;
};

export default OccupancyStatusChip;
