import { format } from "date-fns";

export const constructAddress = (rec) => {
  return `${rec.addr_street}, ${rec.addr_city}, ${rec.addr_state} ${rec.addr_zip}`;
};

export const ucWord = (str) => `${str[0].toUpperCase()}${str.slice(1)}`;

export const formatSpeedTier = (speedTier) => {
  if (typeof speedTier !== "number") {
    return "";
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 0,
  });
  return `${formatter.format(speedTier)}Mbps`;
};

export const formatPrice = (priceInCents, digits = 0) => {
  if (typeof priceInCents !== "number") {
    return "";
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
  const priceInDollers = priceInCents / 100;
  return formatter.format(priceInDollers);
};

export const formatName = (firstName, lastName, def = "") => {
  let name = "";

  if (firstName && lastName) {
    name = `${firstName} ${lastName}`;
  } else if (!firstName && !lastName) {
    name = def;
  } else {
    name = `${firstName}${lastName}`;
  }

  return name;
};

export const formatPhone = (phoneStr) => {
  if (typeof phoneStr !== "string") {
    return "";
  }

  if (!phoneStr) {
    return "";
  }

  const areaCode = phoneStr.substring(0, 3);
  const exchange = phoneStr.substring(3, 6);
  const number = phoneStr.substring(6, 10);

  return `(${areaCode}) ${exchange}-${number}`;
};

export const formatDateStr = (dateStr) => {
  if (typeof dateStr !== "string") {
    return "";
  }
  const [year, month, day] = dateStr.split("-");
  return `${month}/${day}/${year}`;
};

export const formatDateObjAsDateStr = (dateObj) => {
  if (!dateObj || dateObj.constructor !== Date) {
    return "";
  }

  const y = dateObj.getFullYear();
  const m = (dateObj.getMonth() + 1).toString();
  const d = dateObj.getDate().toString();

  return `${m}/${d}/${y}`;
};

export const formatDateObjAsHumanReadable = (dateObj) => {
  if (!dateObj || dateObj.constructor !== Date) {
    return "";
  }

  return format(dateObj, "MMM d, yyyy");
};
