import { useOutletContext } from "react-router";
import withInitialApiCall from "../../HOC/withInitialApiCall";
import PageUsers from "../Page/PageUsers";

const PageUsersLoader = () => {
  const { api } = useOutletContext();

  return withInitialApiCall(PageUsers, () => api.listUsers(), "Users")();
};

export default PageUsersLoader;
