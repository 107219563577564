import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Typography } from "@mui/material";

const PageLogout = () => {
  const navigate = useNavigate();
  useEffect(() => navigate("/"));
  return <Typography>Logging out...</Typography>;
};

export default PageLogout;
