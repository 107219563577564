import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import MoneyCard from "./PageDashboard/MoneyCard";
import MessageListCard from "../UIBits/MessageListCard";
import AccountTakeRateCard from "./PageDashboard/AccountTakeRateCard";
import { MessageList } from "../UIBits/MessageListCard";
import { useState } from "react";

const PageDashboard = ({ pageData }) => {
  const [msgDialogOpen, setMsgDialogOpen] = useState(false);
  const openMsgDialog = () => setMsgDialogOpen(true);
  const closeMsgDialog = () => setMsgDialogOpen(false);

  return (
    <>
      <Grid container columnSpacing={3} rowSpacing={3}>
        <Grid item xs={12}>
          <MessageListCard
            messageList={pageData.messages.slice(0, 4)}
            openMsgDialogFn={openMsgDialog}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <AccountTakeRateCard {...pageData.aggs} />
        </Grid>
        <Grid item sm={12} lg={6}>
          <MoneyCard
            revenue={pageData.aggs.revenue}
            expenses={pageData.aggs.expenses}
            noi={pageData.aggs.noi}
          />
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={msgDialogOpen}
        onClose={closeMsgDialog}
      >
        <DialogTitle>All Messages</DialogTitle>
        <DialogContent>
          <MessageList messageList={pageData.messages} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PageDashboard;
