import { useOutletContext } from "react-router";
import { useParams } from "react-router-dom";
import withInitialApiCall from "../../HOC/withInitialApiCall";
import PageUnit from "../Page/PageUnit";

const PageUnitLoader = () => {
  const { api } = useOutletContext();
  const { unitId } = useParams();

  return withInitialApiCall(
    PageUnit,
    () => api.getUnit(unitId),
    "Unit",
    (data) => ({
      title: `${data.prop_name} : ${data.bld_name} : Unit ${data.num}`,
      backBtn: true,
    })
  )();
};

export default PageUnitLoader;
