import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
} from "@mui/material";
import { useOutletContext } from "react-router";
import { format } from "date-fns"

const PageInvoices = ({pageData}) => {
  const { api } = useOutletContext();

  let numFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return (
    <>
    <Card elevation={0} variant="outlined" sx={{ borderRadius: 2}}>
      <CardHeader
        titleTypographyProps={{ fontSize: "larger" }}
        title="Invoices Table"
      />
      <CardContent>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ "& > th": { whiteSpace: "noWrap" } }}>
              <TableCell>Invoice #</TableCell>
              <TableCell>Property</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Issue Date</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData.map((i) => (
              <TableRow key={`invoiceId-${i.id}`}>
                <TableCell>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={async () => {
                      try {
                        const response = await api.getInvoicePDFLink(i.id)
                        window.open(response.pdf_url, '_blank', 'noopener,noreferrer');
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                    arai-label={`Open pdf invoice ${i.id} in a a new tab`}
                  >
                    INV-{i.id}
                  </Link>
                </TableCell>
                <TableCell>
                  {i.prop_name}
                </TableCell>
                <TableCell>
                  {i.status}
                </TableCell>
                <TableCell>
                  {format(new Date(i.created_on), "MMM dd, yyyy")}
                </TableCell>
                <TableCell>
                  {format(new Date(i.due_date), "MMM dd, yyyy")}
                </TableCell>
                <TableCell>
                  ${numFormatter.format(i.amount_due / 100)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
    </>
  );
}

export default PageInvoices;

