import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import SSNoDefinedStuff from "./SSNoDefinedStuff";
import HumCard from "./HumCard";
import { format } from "date-fns";
import PrimaryButton from "./PrimaryButton";
import InternalLink from "./InternalLink";

export const MessageList = ({ messageList }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Property</TableCell>
          <TableCell>Message</TableCell>
          <TableCell sx={{ width: "130px" }}>Type</TableCell>
          <TableCell sx={{ width: "130px" }}>Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {messageList.map((m) => (
          <TableRow key={m.id}>
            <TableCell sx={{ whiteSpace: "nowrap" }}>
              {m.property_title && (
                <InternalLink
                  to={`/properties/${m.property_id}`}
                  label={m.property_title}
                />
              )}
            </TableCell>
            <TableCell>{m.body}</TableCell>
            <TableCell>
              {m.type === "alert" ? (
                <Box component="span" sx={{ color: "error.main" }}>
                  Alert
                </Box>
              ) : (
                "Message"
              )}
            </TableCell>
            <TableCell>
              {format(new Date(m.created_on), "MMM d, yyyy")}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const MessageListCard = ({ messageList, openMsgDialogFn }) => {
  return (
    <HumCard>
      <CardHeader title="Message Center" />
      {messageList.length ? (
        <>
          <CardContent>
            <MessageList messageList={messageList} />
          </CardContent>
          <CardActions sx={{ justifyContent: "right" }}>
            <PrimaryButton onClick={openMsgDialogFn} variant="contained">
              View All Messages
            </PrimaryButton>
          </CardActions>
        </>
      ) : (
        <CardContent>
          <SSNoDefinedStuff message="No Messages" minHeight="100px" />
        </CardContent>
      )}
    </HumCard>
  );
};

export default MessageListCard;
