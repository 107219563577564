import {
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  CardMedia,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { constructAddress } from "../helpers";
import SecondaryButton from "./UIBits/SecondaryButton";

const CARD_WIDTH = 340;
const CARD_HEIGHT = 190;

const CardHeaderNoWrap = styled(CardHeader)({
  "& .MuiCardHeader-content": {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  "& .MuiTypography-root": {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
});

const PropertyCard = ({ property, navigationTarget }) => {
  const navigate = useNavigate();
  return (
    <Card
      variant="outlined"
      sx={{ borderRadius: 2, width: CARD_WIDTH, maxWidth: CARD_WIDTH }}
    >
      <CardActionArea onClick={() => navigate(navigationTarget)}>
        <CardMedia
          component="img"
          height={CARD_HEIGHT}
          image={property.picture}
        />
      </CardActionArea>
      <CardHeaderNoWrap
        title={property.name}
        subheader={constructAddress(property)}
        subheaderTypographyProps={{
          sx: { fontSize: "smaller" },
        }}
      />

      <CardActions sx={{ justifyContent: "right" }}>
        <SecondaryButton onClick={() => navigate(navigationTarget)}>
          Details
        </SecondaryButton>
      </CardActions>
    </Card>
  );
};

export default PropertyCard;
