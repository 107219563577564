import { Card } from "@mui/material";

const HumCard = ({ children, sx = {} }) => {
  return (
    <Card variant="outlined" sx={{ ...sx, borderRadius: 2 }}>
      {children}
    </Card>
  );
};

export default HumCard;
